import {
  MouseEvent,
  useState,
  ChangeEvent,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import useIsMounted from '../hooks/useIsMounted';
import useQuery from '../hooks/useQuery';
import api from '../services/api';
import { noteOptions } from '../mocks/noteOptions';
import { ISearchSmsQuestionControlShipping } from '../@types';

import {
  Container,
  Wrapper,
  Header,
  WrapperSurvey,
  Title,
  SubTitle,
  Body,
  Wouldindicate,
  FooterButton,
  ButtonSend,
  TitleIndicate,
  ContentButtonsNps,
  ButtonNps,
  ContentImprovement,
  TitleImprovement,
  InputLines,
  ContentLines,
} from './styles';
import surveyNps from '../assets/survey-nps.svg';
import { Spinner } from '../components';

export default function SurveyNps() {
  const isFirstRender = useRef(true);
  const query = useQuery();
  const history = useHistory();
  const isMounted = useIsMounted();

  const hash = query.get('v');
  const noteInfo = query.get('nota');

  const [valueNote, setValueNote] = useState(-1);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [loading, setLoading] = useState(false);
  const [reviewUser, setReviewUser] = useState('');
  const [infoSurveyNps, setInfoSurveyNps] =
    useState<ISearchSmsQuestionControlShipping | null>(null);

  const getSurveyNps = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await api.get(
        `${
          process.env.REACT_APP_URL_API
        }PesquisaSMSPerguntaControleEnvio/buscar-pesquisa-hash?hash=${String(
          hash,
        )}`,
      );

      if (data && data?.retorno?.isExpired && isMounted()) {
        history.push('/survey-expired');
        return;
      }

      if (
        data &&
        data?.retorno?.idPesquisaSMSPerguntaCliente > 0 &&
        isMounted()
      ) {
        history.push('/survey-answered');
        return;
      }

      if (data && data?.sucesso === false && isMounted()) {
        history.push('/not-exists');
        return;
      }

      if (isMounted()) {
        setInfoSurveyNps(data);
        setLoadingInitial(false);
      }
    } catch (err) {
      console.log(err);
      history.push('/not-exists');
      return;
    } finally {
      setLoading(false);
    }

    return true;
  }, [isMounted, history]);

  const getAndSavedNoteForEmailAutomatic = useCallback(async () => {
    setLoading(true);

    const resquestBody = {
      hash,
      nota: noteInfo,
    };

    try {
      const { data, status } = await api.post(
        `${process.env.REACT_APP_URL_API}RespostaNps/salvar-pesquisa-nps-email`,
        resquestBody,
      );

      if (data && data?.sucesso === false && status === 202 && isMounted()) {
        history.push('/survey-expired');
        return;
      }
    } catch (err) {
      console.log(err);
      history.push('/survey-expired');
      return;
    } finally {
      setLoadingInitial(false);
      setLoading(false);
    }

    return true;
  }, [isMounted, history]);

  useEffect(() => {
    const localStorageValueNote = localStorage.getItem('@HavanLabs:valueNote');
    const localStorageReviewUser = localStorage.getItem(
      '@HavanLabs:reviewUser',
    );
    if (localStorageValueNote) {
      setValueNote(Number(localStorageValueNote));
    }
    if (localStorageReviewUser) {
      setReviewUser(localStorageReviewUser);
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      if (noteInfo) {
        getAndSavedNoteForEmailAutomatic();
      } else {
        getSurveyNps();
      }

      isFirstRender.current = false;
    }
  }, [getSurveyNps, getAndSavedNoteForEmailAutomatic]);

  function handleSelectedValueNote(note: number) {
    setValueNote(note);
  }

  function handleChangeReviewUser(event: ChangeEvent<HTMLTextAreaElement>) {
    setReviewUser(event.target.value);
  }

  const handleSavedSurveyNps = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (valueNote < 0) {
      toast.error('Selecione uma nota para realizar o cadastro');
      return;
    }

    if (await getSurveyNps()) {
      const resquestBody = {
        idPesquisaSMSPerguntaControleEnvio: infoSurveyNps?.retorno?.id,
        nota: valueNote,
        justificativa: reviewUser
          .trim()
          .replace(/^ +/g, '')
          .replace(/  +/g, ' '),
        dataCadastro: infoSurveyNps?.retorno?.dataCadastro,
        idPesquisaSmsPerguntaClienteOrigem:
          infoSurveyNps?.retorno?.idPesquisaSmsPerguntaClienteOrigem,
        origem: infoSurveyNps?.retorno?.origem,
      };

      try {
        const { data, status } = await api.post(
          `${process.env.REACT_APP_URL_API}RespostaNps/salvar-pesquisa-nps`,
          resquestBody,
        );

        if (data && data?.sucesso === false && status === 202 && isMounted()) {
          localStorage.setItem('@HavanLabs:valueNote', String(valueNote));
          localStorage.setItem('@HavanLabs:reviewUser', reviewUser);
          history.push('/survey-not-sent');
          return;
        }

        if (data && data?.sucesso === false && isMounted()) {
          localStorage.setItem('@HavanLabs:valueNote', String(valueNote));
          localStorage.setItem('@HavanLabs:reviewUser', reviewUser);
          history.push('/survey-not-sent');
          return;
        }

        if (data && isMounted()) {
          localStorage.removeItem('@HavanLabs:valueNote');
          localStorage.removeItem('@HavanLabs:reviewUser');
          history.push('/survey-sent');
        }
      } catch (error) {
        console.log(error);
        localStorage.setItem('@HavanLabs:valueNote', String(valueNote));
        localStorage.setItem('@HavanLabs:reviewUser', reviewUser);
        history.push('/survey-not-sent');
      } finally {
        if (isMounted()) {
          setLoading(false);
        }
      }
    }
  };

  const handleSavedJustifyForNoteAutomatic = async (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    setLoading(true);

    const resquestBody = {
      hash,
      justificativa: reviewUser.trim().replace(/^ +/g, '').replace(/  +/g, ' '),
    };

    try {
      const { data, status } = await api.post(
        `${process.env.REACT_APP_URL_API}RespostaNps/alterar-pesquisa-nps-email`,
        resquestBody,
      );

      if (data && data?.sucesso === false && status === 202 && isMounted()) {
        history.push('/survey-not-sent');
        return;
      }

      if (data && data?.sucesso === false && isMounted()) {
        history.push('/survey-not-sent');
        return;
      }

      if (data && isMounted()) {
        history.push('/survey-sent');
      }
    } catch (err) {
      console.log(err);
      history.push('/survey-not-sent');
      return;
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  if (loadingInitial && !infoSurveyNps) {
    return (
      <Container style={{ marginTop: 250 }}>
        <Spinner color="#06357a" height={90} width={90} type="spin" />
      </Container>
    );
  }

  const messageHacked = infoSurveyNps?.retorno?.mensagem
    ? `${infoSurveyNps?.retorno?.mensagem.slice(
        0,
        infoSurveyNps?.retorno?.mensagem.indexOf('?'),
      )}?`
    : 'Olá Cliente Havan';

  return (
    <Container>
      <Wrapper>
        <Header>
          <WrapperSurvey>
            <img src={surveyNps} alt="Pesquisa NPS" />
          </WrapperSurvey>
          <Title>{messageHacked}</Title>
          <SubTitle>
            {/* Em uma nota de 0 a 10, o quanto você indicaria a Havan para um amigo
            ou familiar? */}
          </SubTitle>
        </Header>

        <Body>
          {!noteInfo && (
            <>
              <Wouldindicate>
                <TitleIndicate>Não indicaria</TitleIndicate>
                <TitleIndicate>Indicaria</TitleIndicate>
              </Wouldindicate>
              <ContentButtonsNps>
                {noteOptions.map(item => {
                  return (
                    <ButtonNps
                      selectedValue={item.valueNote === valueNote}
                      key={item.valueNote}
                      onClick={() => handleSelectedValueNote(item.valueNote)}
                      disabled={loading}
                    >
                      {item.valueNote}
                    </ButtonNps>
                  );
                })}
              </ContentButtonsNps>
            </>
          )}

          <ContentImprovement>
            <TitleImprovement>
              Conta pra gente o que podemos melhorar:
            </TitleImprovement>
            <InputLines
              limitInput={reviewUser.length === 4000}
              rows={4}
              maxLength={4000}
              value={reviewUser}
              onChange={event => handleChangeReviewUser(event)}
            />
          </ContentImprovement>
          <ContentLines limitInput={reviewUser.length === 4000}>
            {reviewUser.length === 4000 && (
              <span>Você atingiu o limite de caractéres</span>
            )}
            <span>{`${reviewUser.length}/4000`}</span>
          </ContentLines>
        </Body>
        <FooterButton>
          <ButtonSend
            type="button"
            onClick={
              !noteInfo
                ? handleSavedSurveyNps
                : handleSavedJustifyForNoteAutomatic
            }
            disabled={(valueNote < 0 && !noteInfo) || loading}
            loading={loading}
          >
            Enviar
          </ButtonSend>
        </FooterButton>
      </Wrapper>
    </Container>
  );
}
